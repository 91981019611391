import {createSlice} from '@reduxjs/toolkit'

export const slice = createSlice({
    name: 'user',
    initialState: {
        roles: [],
        username: ''
    },
    reducers: {
        setRoles: (state, action) => {
            state.roles = action.payload
        },
        setUsername: (state, action) => {
            state.username = action.payload
        }
    }
})

export const {setRoles, setUsername} = slice.actions

export const selectUser = state => state.user

export default slice.reducer