import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row
} from "reactstrap";
import confirm from "reactstrap-confirm";
import { useState, useEffect } from "react";
import { examinationService } from "Services";
import { selectUser } from 'reducers/userSlice'
import { useSelector } from 'react-redux'
import Header from "components/Headers/Header.js";
import history from "../../history";
import { useAlert } from 'react-alert'
const Examinations = () => {
  const user = useSelector(selectUser)
  const alert = useAlert()
  const [pageHeader, setPageHeader] = useState('')
  const [locationUser, setLocationUser] = useState(null)
  useEffect(() => {
    var user = history.location.state?.user;
    setLocationUser(user)
    alert.info('Trwa wczytywanie badań...')
    if (user) {
      setPageHeader("Badania użytkownika " + user.firstName + " " + user.lastName)
      examinationService.getUserExaminationsById(user.id).then(setExaminations(examinations)).then((resp => setExaminations(resp)));
      
    } else {
      setPageHeader("Moje badania")
      examinationService.getUserExaminations(setExaminations(examinations)).then((resp => setExaminations(resp)));
     
    }
  }, [])
  const handleExaminationDownload = (examination) => {
    alert.info('Trwa pobieranie badania...')
    if (user.roles.includes('Administrator')) {
      examinationService.downloadPdf(examination.id, examination.filename)
    } else {
      examinationService.downloadPdfByToken(examination.id, examination.filename)
    }
  }
  const [examinations, setExaminations] = useState([]);
  const createRow = (examination) => {
    return <tr key={examination.id}>
      <td>
        {examination.date.substring(0, 10)}
      </td>
      <th scope="row">
        {examination.filename}
      </th>
      <td className="text-right">
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light"
            role="button"
            size="sm"
            color=""
            onClick={(e) => e.preventDefault()}
          >
            <span style={{ color: 'black' }}>
              <i className="fas fa-ellipsis-v fa-lg" />
            </span>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem
              onClick={() => handleExaminationDownload(examination)}
            >
              Pobierz badanie
            </DropdownItem>

            {user.roles.includes('Administrator') &&
              <DropdownItem
                onClick={async () => {
                  let result = await confirm({
                    title: (
                      <>
                        Ostrzeżenie!
                      </>
                    ),
                    message: "Czy na pewno chcesz usunąć badanie?",
                    confirmText: "Tak",
                    confirmColor: "primary",
                    cancelText: "Nie",
                    cancelColor: "link text-danger"
                  });
                  if (result) {
                    alert.info('Trwa usuwanie badania...')
                    examinationService.deleteFile(examination.id).then(res => {
                      alert.success(res.message)
                      if (locationUser) {
                        examinationService.getUserExaminationsById(locationUser.id).then(setExaminations(examinations)).then((resp => setExaminations(resp)));
                      } else {
                        examinationService.getUserExaminations(setExaminations(examinations)).then((resp => setExaminations(resp)));
                      }
                    }).catch(err => alert.error(err.message))
                  }
                }}
              >
                Usuń badanie
              </DropdownItem>
            }

          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  }
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">{pageHeader}</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive style={{ paddingDown: '60vh' }}>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Data badania</th>
                    <th scope="col">Nazwa pliku</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {examinations.map((item, i) => createRow(item))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Examinations;
