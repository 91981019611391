import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,

} from "reactstrap";
import { selectUser } from 'reducers/userSlice'
import { useSelector } from 'react-redux'
import { useState } from "react";
import history from "../../history"
import logout from "utils/logout";
const AdminNavbar = (props) => {
  const user = useSelector(selectUser)
  const [searchTerm, setSearchTerm] = useState('')
  const search = () => {
    if (window.location.href.includes('/admin/users')) {
      history.push({ pathname: '/admin/users', search: '?filter=' + searchTerm })
    }
  }
  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          {window.location.href.includes('/admin/users') && <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto" onSubmit={(e) => e.preventDefault()}>
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Wyszukaj po imieniu lub nazwisku" type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} onKeyPress={(e) => {
                  if (e.charCode === 13)
                    search()
                }}
                />
              </InputGroup>
            </FormGroup>
          </Form>
          }
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <i class="fas fa-user"></i>
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {user ? user.username : ''}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Witaj!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/change-password" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Zmiana hasła</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={(e) => { e.preventDefault(); logout(); }}>
                  <i className="ni ni-user-run" />
                  <span>Wyloguj</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
