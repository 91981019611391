import Users from "views/examples/Users.js";
import Examinations from "views/examples/Examinations";
import CreateUser from "views/examples/CreateUser";
var adminRoutes = [
  {
    path: "/users",
    name: "Użytkownicy",
    icon: "fas fa-users text-blue",
    component: Users,
    layout: "/admin",
  },
  {
    path: "/examinations",
    name: "Badania",
    icon: "ni ni-bullet-list-67 text-red",
    component: Examinations,
    layout: "/admin",
  },
  {
    path: "/create-user",
    name: "Dodaj użytkownika",
    icon: "fas fa-user-plus text-green",
    component: CreateUser,
    layout: "/admin",
  }
];
export default adminRoutes;
