import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { selectUser } from 'reducers/userSlice';
import { useSelector } from 'react-redux';
const AdminRoute = ({ component: Component, ...rest }) => {
    const user = useSelector(selectUser)
    return(
        <Route {...rest} render={(props) => (
            user.roles.length === 0 
            ? <Redirect to="/auth/unauthorized" />
            : user.roles.includes('Administrator')
                ? <Component {...props} />
                : <Redirect to='/auth/forbidden' />
        )} />
    )}
export default AdminRoute;