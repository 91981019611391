import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
} from "reactstrap";
import {useState, useEffect} from "react"
import history from "../../history";
import { userService } from "Services";
import { useAlert } from 'react-alert'

const EditUser = () => {
    const alert = useAlert()
    const [user, setUser] = useState({})
    useEffect(() => {
        const user = history.location.state?.user
        if (!user) {
            history.push('/admin/users')
        }
        setUser(user)
    }, [])

    const updateUser = () => {
        alert.info('Trwa wysyłanie zmian...')
        userService.editUser(user.id, user).then(res => {
            alert.success(res.message)
        }).catch(err => {
            alert.error(err)
        })
    }
    return (
        <>
            <div
                className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
                style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center top",
                }}
            >
                {/* Mask */}
                <span className="mask bg-gradient-default opacity-8" />
                {/* Header container */}
                <Container className="d-flex align-items-center" fluid>
                </Container>
            </div>
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                            <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Edycja użytkownika</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={(e) => {e.preventDefault(); updateUser();}}>
                                    <h6 className="heading-small text-muted mb-4">
                                        Dane osobowe
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        Nazwa użytkownika
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={user.username}
                                                        id="input-username"
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-first-name"
                                                    >
                                                        Imię
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={user.firstName}
                                                        onChange={(e) => setUser({...user, firstName: e.target.value})}
                                                        id="input-first-name"
                                                        type="text"
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-last-name"
                                                    >
                                                        Nazwisko
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={user.lastName}
                                                        onChange={(e) => setUser({...user, lastName: e.target.value})}
                                                        id="input-last-name"
                                                        type="text"
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr className="my-4" />
                                    {/* Address */}
                                    <h6 className="heading-small text-muted mb-4">
                                        Dane kontaktowe
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-email"
                                                    >
                                                        Adres email
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={user.email}
                                                        id="input-email"
                                                        type="email"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-phone"
                                                    >
                                                        Numer telefonu
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={user.phoneNumber}
                                                        onChange={(e) => setUser({...user, phoneNumber: e.target.value})}
                                                        id="input-phone"
                                                        type="phone"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-city"
                                                    >
                                                        Miejscowość
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={user.city}
                                                        onChange={(e) => setUser({...user, city: e.target.value})}
                                                        id="input-city"
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-postal-code"
                                                    >
                                                        Kod pocztowy
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={user.postalCode}
                                                        onChange={(e) => setUser({...user, postalCode: e.target.value})}
                                                        id="input-postal-code"
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-address"
                                                    >
                                                        Adres
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={user.address}
                                                        onChange={(e) => setUser({...user, address: e.target.value})}
                                                        id="input-address"
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <Button type="submit" color="success" style={{ width: '100%' }}>Zapisz</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default EditUser;
