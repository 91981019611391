import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { selectUser } from 'reducers/userSlice';
import { useSelector } from 'react-redux';
const UserRoute = ({ component: Component, ...rest }) => {
    const user = useSelector(selectUser)
    return(
        <Route {...rest} render={(props) => (
            user.roles.includes("Administrator")
                ? <Redirect to='/admin/users' />
                : user.roles.includes("User")
                    ? <Component {...props} />
                    : <Redirect to='/auth/forbidden' />
        )} />
    )}
export default UserRoute;