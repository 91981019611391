import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { userService } from "Services";
import { setRoles, setUsername } from "reducers/userSlice"
import { userActions } from "actions";
import history from '../../history';
import { useAlert } from 'react-alert'
import { Alert } from "reactstrap";

const Login = (props) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('')
  const [loggingIn, setLoggingIn] = useState(false)
  const alert = useAlert()
  const dispatch = useDispatch()
  useEffect(() => {
    userActions.clearData(dispatch)
  }, [])

  const handleLogin = () => {
    setLoggingIn(true)
    userService.login(login, password).then((resp) => {
      localStorage.setItem('jwt', resp.token);
      localStorage.setItem('roles', resp.roles);
      dispatch(setRoles(resp.roles) || [])
      dispatch(setUsername(resp.displayName) || "")
      setLoggingIn(false)
      if (resp.roles.includes('Administrator')) {
        history.push('/admin/index')
      } else {
        history.push('/user/examinations')
      }
    }).catch((err) => {
      setLoggingIn(false)
      alert.error(err)
    })
  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Zaloguj się do systemu Eurocardio</small>
            </div>
            <Form role="form" onKeyPress={(e) => e.charCode === 13 && handleLogin()}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={login}
                    onChange={(e) => setLogin(e.target.value)}
                    placeholder="Nazwa użytkownika lub adres email"
                    type="text"
                    autoComplete="new-email"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Hasło"
                    type="password"
                  />
                </InputGroup>
                {loggingIn &&
                  <Alert style={{position: 'fixed', bottom: '2vw', right: '2vw', textAlign: 'center'}}
                    color="primary"
                  >
                    Trwa logowanie...
                  </Alert>}
              </FormGroup>
              <div className="text-center">
                <Button className="my-4" color="primary" type="button" onClick={() => handleLogin()}>
                  Zaloguj się
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              onClick={() => history.push("/auth/forgot-password")}
            >
              Zapomniałeś hasła?
            </a>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
