import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

import Login from "views/examples/Login";
import Empty from "views/examples/Empty";
import ForgotPassword from "views/examples/ForgotPassword";
import ResetPassword from "views/examples/ResetPassword";
const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);
  const createStatusCodeMessageH1 = () => {
    var message = "";
    switch (location.pathname) {
      case "/auth/unauthorized": message = "401 Unauthorized"; break;
      case "/auth/forbidden": message = "403 Forbidden"; break;
      default: message = ""
    }
    return message !== "" && <h1 className="text-white">{message}</h1>
  }
  return (
    <>
      <div className="main-content" ref={mainContent}>
        <AuthNavbar />
        <div className="header bg-gradient-info py-7 py-lg-8">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                {createStatusCodeMessageH1()}
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Switch>
                  <Route
                path={"/auth/login"}
                key={"/auth/login"}
                component={Login}
              />
              <Route
                path={"/auth/forgot-password"}
                key={"/auth/forgot-password"}
                component={ForgotPassword}
              />
              <Route
                path={"/auth/reset-password"}
                key={"/auth/reset-password"}
                component={ResetPassword}
              />
              <Route
                path={"/auth/unauthorized"}
                key={"/auth/unauthorized"}
                component={Empty}
              />
              <Route
                path={"/auth/forbidden"}
                key={"/auth/forbidden"}
                component={Empty}
              />
              <Redirect from="*" to="/auth/login" />
            </Switch>
          </Row>
        </Container>
      </div>
      <AuthFooter />
    </>
  );
};

export default Auth;
