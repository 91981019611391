import { configureStore } from '@reduxjs/toolkit'
import userReducer from './reducers/userSlice'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import {combineReducers} from "redux"; 
const reducers = combineReducers({
    user: userReducer
})
const persistConfig = {
    key: 'root',
    storage
};
const persistedReducer = persistReducer(persistConfig, reducers)
export default configureStore({
  reducer: persistedReducer
})