import Examinations from "views/examples/Examinations";
var userRoutes = [
  {
    path: "/examinations",
    name: "Badania",
    icon: "ni ni-bullet-list-67 text-red",
    component: Examinations,
    layout: "/user",
  }
];
export default userRoutes;
