import logout from "utils/logout"
const apiUrl = 'https://ecardiopl.hostingasp.pl/api'

export const userService = {
  login,
  createUser,
  getUsersPage,
  getPageCount,
  getUserFullName,
  changePassword,
  deleteUser,
  editUser,
  createResetPasswordLink,
  checkResetPasswordToken,
  resetPassword
}

function login (login, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ login, password })
  }

  return fetch(`${apiUrl}/User/Login`, requestOptions).then(handleResponse)
}

function createUser (user, role) {
  const requestOptions = {
    method: 'POST',
    headers: { Authorization: 'Bearer ' + localStorage.getItem('jwt'), 'Content-Type': 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  }

  return fetch(`${apiUrl}/User/Create?role=${role}`, requestOptions).then(handleResponse)
}

function getUsersPage(page, query){
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: 'Bearer ' + localStorage.getItem('jwt'), 'Content-Type': 'application/json'}
  }


  return fetch(`${apiUrl}/User/UsersByPage/${page}${query}`, requestOptions).then(handleResponse)
}

function getPageCount(){
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: 'Bearer ' + localStorage.getItem('jwt'), 'Content-Type': 'application/json'}
  }
  
  return fetch(`${apiUrl}/User/PageCount`, requestOptions).then(handleResponse)
}

function getUserFullName(){
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: 'Bearer ' + localStorage.getItem('jwt'), 'Content-Type': 'application/json'}
  }

  return  fetch(`${apiUrl}/User/FullName`, requestOptions).then(response => {
    if(!response.ok){
      return Promise.reject()
    }
    return response.text()
  })
}

function changePassword(body){
  const requestOptions = {
    method: 'POST',
    headers: { Authorization: 'Bearer ' + localStorage.getItem('jwt'), 'Content-Type': 'application/json'},
    body: JSON.stringify(body)
  }

  return fetch(`${apiUrl}/User/ChangePassword`, requestOptions).then(handleResponse)
}

function deleteUser(userId){
  const requestOptions = {
    method: 'DELETE',
    headers: { Authorization: 'Bearer ' + localStorage.getItem('jwt'), 'Content-Type': 'application/json'},
  }

  return fetch(`${apiUrl}/User/${userId}`, requestOptions).then(handleResponse)
}

function editUser(userId,userDto){
  const requestOptions = {
    method: 'PUT',
    headers: { Authorization: 'Bearer ' + localStorage.getItem('jwt'), 'Content-Type': 'application/json'},
    body: JSON.stringify(userDto)
  }

  return fetch(`${apiUrl}/User/${userId}`, requestOptions).then(handleResponse)
}

function createResetPasswordLink(email){
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(email)
  }

  return fetch(`${apiUrl}/User/ResetPasswordLink`, requestOptions).then(handleResponse)
}

function checkResetPasswordToken(token){
  return fetch(`${apiUrl}/User/CheckResetToken/${token}`).then(handleResponse)
}

function resetPassword(body){
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(body)
  }

  return fetch(`${apiUrl}/User/ResetPassword`, requestOptions).then(handleResponse)
}

function handleResponse (response) {
  return response.text().then(text => {
    try {
      const data = text && JSON.parse(text)
      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          logout()
        }
        const error = (data && data.message) || response.statusText
        return Promise.reject(error)
      }
      return data
    } catch (e){
      console.log(e)
    }
    
  })
}
