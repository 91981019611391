import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import { examinationService } from "Services";
import { useState, useEffect } from "react";
import history from "../../history";
import { useAlert } from 'react-alert'

const AddExamination = (props) => {
  const [files, setFiles] = useState()
  const alert = useAlert()
  const [examinationDate, setExaminationDate] = useState('');

  useEffect(() => {
    if (!history.location.state?.user) {
      history.push('/admin/users')
    }
  }, [])
  const sendExamination = () => {
    const user = history.location.state?.user
    alert.info('Trwa dodawanie badania')
    examinationService.sendExamination(files, examinationDate, user.id).then((res) => {
      alert.success(res.message)
    }).catch(err => {
      alert.error('Nie udało się utworzyć badania')
    })
  }

  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Nowe badanie</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={(e) => {
                  e.preventDefault();
                  sendExamination()
                } }>
                  <h6 className="heading-small text-muted mb-4">
                    Dane osobowe
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Data przeprowadzenia badania
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={examinationDate}
                            onChange={(e) => setExaminationDate(e.target.value)}
                            id="input-username"
                            placeholder="Username"
                            type="datetime-local"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Plik badania
                          </label>
                          <Input
                            className="form-control-alternative"
                            onChange={(event) => { setFiles(event.currentTarget.files); }}
                            id="input-username"
                            placeholder="Username"
                            type="file"
                            multiple="multiple"
                            required
                          />

                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Button color="primary" type = "submit" >
                        Wyślij badanie
                      </Button>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddExamination;
