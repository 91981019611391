import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
} from "reactstrap";
import Header from "components/Headers/Header.js";
import { userService } from "Services";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useAlert } from 'react-alert'
import history from "../../history";
import Pagination from '@mui/material/Pagination';
import confirm from "reactstrap-confirm";

function useLocationChange (action){
  const location = useLocation()

  useEffect(() => { 
    action(location) 
  }, [location])
}
const Users = () => {
  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState(1)
  const [filter, setFilter] = useState('')
  const [page, setPage] = useState(1)
  const alert = useAlert()
  useLocationChange((location) => {
    const search = location.search
    setFilter(search)
    if (search !== "")
      userService.getUsersPage(1, search, false).then(x => { setUsers(x.users); setUsersCount(x.count) });
  })
  const createRow = (user) => {
    return <tr key={user.id}>
      <td>
        {user.role}
      </td>
      <td>
        {user.username}
      </td>
      <th scope="row">
        {user.firstName + " " + user.lastName}
      </th>
      <td>{user.phoneNumber}</td>
      <td>
        {user.email}
      </td>
      <td>
        {user.city}
      </td>
      <td className="text-right">
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={(e) => e.preventDefault()}
          >
            <span style={{ color: 'black' }}>
              <i className="fas fa-ellipsis-v fa-lg" />
            </span>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem
              onClick={() => history.push({ pathname: '/admin/edit-user', state: { user: user } })}
            >
              Edytuj użytkownika
            </DropdownItem>
            <DropdownItem
              onClick={() => history.push({ pathname: '/admin/add-examination', state: { user: user } })}
            >
              Dodaj badanie
            </DropdownItem>
            <DropdownItem
              onClick={() => history.push({ pathname: '/admin/examinations', state: { user: user } })}
            >
              Wyświetl badania
            </DropdownItem>
            <DropdownItem
              onClick={ async() => {
                let result = await confirm({
                  title: (
                    <>
                      Ostrzeżenie!
                    </>
                  ),
                  message: "Czy na pewno usunąć użytkownika?",
                  confirmText: "Tak",
                  confirmColor: "primary",
                  cancelText: "Nie",
                  cancelColor: "link text-danger"
                });
                if (result) {
                  alert.info('Trwa usuwanie użytkownika...')
                  userService.deleteUser(user.id).then((res) => {
                    alert.success(res.message)
                    if (users.length > 1) {
                      userService.getUsersPage(page, filter).then(x => { setUsers(x.users); setUsersCount(x.count) });
                    } else {
                      if (page > 1) {
                        userService.getUsersPage(page - 1, filter).then(x => { setUsers(x.users); setUsersCount(x.count) });
                        setPage(prev => prev - 1)
                      } else {
                        userService.getUsersPage(1, filter).then(x => { setUsers(x.users); setUsersCount(x.count) });
                      }
                    }
                  }).catch(err => alert.error(err))
                }
              }}
            >
              Usuń użytkownika
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  }

  const handlePageChange = (event, value) => {
    setPage(value)
    userService.getUsersPage(value, filter, false).then(x => { setUsers(x.users); setUsersCount(x.count) });
  }
  useEffect(() => {
    alert.info('Trwa wczytywanie użytkowników...')
    userService.getUsersPage(1, "", false).then(x => { setUsers(x.users); setUsersCount(x.count) });
  }, [])
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Użytkownicy</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive style={{ minHeight: '27vh' }}>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Rola</th>
                    <th scope="col">Nazwa użytkownika</th>
                    <th scope="col">Imię i nazwisko</th>
                    <th scope="col">Nr telefonu</th>
                    <th scope="col">Adres e-mail</th>
                    <th scope="col">Miejscowość</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {users.map((item, i) => createRow(item))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination onChange={handlePageChange} count={Math.ceil(usersCount / 10)} color="primary" />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Users;
