import logout from "utils/logout"
const apiUrl = 'https://ecardiopl.hostingasp.pl/api'

export const examinationService = {
  getUserExaminations,
  downloadPdfByToken,
  sendExamination,
  getUserExaminationsById,
  downloadPdf,
  deleteFile
}

function getUserExaminations () {
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: 'Bearer ' + localStorage.getItem('jwt'), 'Content-Type': 'application/json'}
  }

  return window.fetch(`${apiUrl}/Examination/UserExaminations/token`, requestOptions).then(handleResponse)
}

function getUserExaminationsById(userId){
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: 'Bearer ' + localStorage.getItem('jwt'), 'Content-Type': 'application/json'}
  }

  return window.fetch(`${apiUrl}/Examination/UserExaminations/${userId}`, requestOptions).then(handleResponse)
}

function downloadPdfByToken(examId, filename){
    const requestOptions = {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + localStorage.getItem('jwt')} 
      }
    
     window.fetch(`${apiUrl}/Examination/pdf/token/${examId}`, requestOptions)
        .then( (resp) => resp.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            const fileName = filename
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            link.remove()
    })
}

function downloadPdf(examId, filename){
  const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.getItem('jwt')} 
  }
  
   window.fetch(`${apiUrl}/Examination/pdf/${examId}`, requestOptions)
      .then( (resp) => resp.blob())
      .then((blob) => {
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          const fileName = filename
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
  })
}

function deleteFile(examId){
  const requestOptions = {
    method: 'DELETE',
    headers: { Authorization: 'Bearer ' + localStorage.getItem('jwt')}
  }

  return fetch(`${apiUrl}/Examination/examId/${examId}`, requestOptions).then(handleResponse)
}

function sendExamination(files, date, userId) {
  var data = new FormData()
  for(let i = 0; i < files.length; i++) {
    data.append('files', files[i])
  }
  const requestOptions = {
    method: 'POST',
    headers: { Authorization: 'Bearer ' + localStorage.getItem('jwt')},
    body: data
  }

  return fetch(`${apiUrl}/Examination?date=${date}&userId=${userId}`, requestOptions).then(handleResponse)
}

function handleResponse (response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401 || response.status === 403) {
        logout()
      }
      var error = (data && data.message) || response.statusText
      if (response.status === 401 || response.status === 403) {
        error = 'unauthorized';
      }
      return Promise.reject(error)
    }
    return data
  })
}
