import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import { userService } from "Services";
import { useState } from "react";
import { useAlert } from 'react-alert'
const ChangePassword = (props) => {
  const alert = useAlert()
  const [currentPassword, setCurrentPassword] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const changePassword = () => {
    if (password !== confirmPassword) {
      alert.error('Hasła nie są ze sobą zgodne')
      return;
    }
    let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})')
    if (!strongPassword.test(password)) {
      alert.error('Hasło powinno zawierać co najmniej: jedną cyfrę, jedną wielką literę, jedną małą literę, 6 znaków')
      return;
    }
    const body = {
      currentPassword,
      password
    }
    userService.changePassword(body).then(() => alert.success('Nowe hasło zostało ustawione')).catch((err) => alert.error(err))
  }

  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <span className="mask bg-gradient-default opacity-8" />
        <Container className="d-flex align-items-center" fluid>
        </Container>
      </div>
      <Container className="mt--7" fluid>

        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Zmiana hasła</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-current-password"
                          >
                            Stare hasło
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            id="input-current-password"
                            placeholder="Stare hasło"
                            type="password"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-new-password"
                          >
                            Nowe hasło
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            id="input-new-password"
                            placeholder="Hasło"
                            type="password"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-confirm-new-password"
                          >
                            Powtórz nowe hasło
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={confirmPassword}
                            onChange={(event) => setConfirmPassword(event.target.value)}
                            id="input-confirm-new-password"
                            placeholder="Powtórz hasło"
                            type="password"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Button color="primary" onClick={() => { changePassword(); }}>Zmień hasło</Button>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ChangePassword;
