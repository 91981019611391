import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import store from './store'
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import UserLayout from 'layouts/User.js'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import history from './history';
import HttpsRedirect from 'react-https-redirect';
let persistor = persistStore(store)
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 4300,
  offset: '5px',
  // you can also just use 'scale'
  transition: transitions.FADE,
}
ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...options}>
    <Provider store={store}>
      <HttpsRedirect>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history}>
            <Switch>
              <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
              <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
              <Route path="/user" render={(props) => <UserLayout {...props} />} />
              <Route exact path="/" render={() => ( 
                localStorage.getItem('jwt')==null ?
                  (<Redirect to="/auth"/>) :
                  localStorage.getItem('roles').includes('Administrator') ?
                    (<Redirect to="/admin"/>) : (<Redirect to="/user"/>)
                )}/>
            
            </Switch>
          </Router>
        </PersistGate>
      </HttpsRedirect>
    </Provider>
  </AlertProvider>
  ,
  document.getElementById("root")
);

