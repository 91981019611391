import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col,
} from "reactstrap";
import { useState, useEffect } from "react";
import { userService } from "Services";
import history from "../../history";
import { useAlert } from 'react-alert'

const ResetPassword = (props) => {
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const alert = useAlert()
    useEffect(() => {
        userService.checkResetPasswordToken(findGetParameter('token') + '==').then().catch(err => {
            alert.error(err)
            history.push("/auth/forgot-password")
        })
    }, [])
    const findGetParameter = (parameterName) => {
        var result = null,
            tmp = [];
        window.location.search
            .substr(1)
            .split("&")
            .forEach(function (item) {
              tmp = item.split("=");
              if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
            });
        return result;
    }
    const handleResetPassword = () => {
        if (password !== confirmPassword) {
            alert.error('Hasła nie są ze sobą zgodne')
            return;
        }
        let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})')
        if (!strongPassword.test(password)){
            alert.error('Hasło powinno zawierać co najmniej: jedną cyfrę, jedną wielką literę, jedną małą literę, 6 znaków')
            return;
        }
        userService.resetPassword({password, resetToken: findGetParameter('token') + '=='}).then(res => alert.success(res.message)).catch(res => alert.error(res))
    }
    return (
        <>
            <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            <small>Ustaw nowe hasło</small>
                        </div>
                        <Form role="form">
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Hasło"
                                        type="password"
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        placeholder="Powtórz Hasło"
                                        type="password"
                                    />
                                </InputGroup>
                            </FormGroup>
                            <div className="text-center">
                                <Button className="my-4" color="primary" type="button" onClick={() => handleResetPassword()}>
                                    Ustaw nowe hasło
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default ResetPassword;
