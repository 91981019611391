import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Label
} from "reactstrap";
import { useState } from "react";
import { userService } from "Services";
import { useAlert } from 'react-alert'

const CreateUser = () => {
    const alert = useAlert()
    const [role, setRole] = useState('User')
    const [username, setUsername] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [city, setCity] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [address, setAddress] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const handleEmailAutoFill = (fname, lname) => {
        var normalizedFname = fname
        .replace(/\u0142/g, "l")
        .normalize('NFKD').replace(/[^\w]/g, '')
        .toLowerCase()
        var normalizedLname = lname
        .replace(/\u0142/g, "l")
        .normalize('NFKD').replace(/[^\w]/g, '')
        .toLowerCase()
        
        var generatedEmail = `${normalizedFname}.${normalizedLname}@reset.e-cardio.pl`
        setEmail(generatedEmail)
    }
    const addUser = () => {
        if (password !== confirmPassword) {
            alert.error('Hasła nie są ze sobą zgodne')
            return;
        }
        let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})')
        if (!strongPassword.test(password)){
            alert.error('Hasło powinno zawierać co najmniej: jedną cyfrę, jedną wielką literę, jedną małą literę, 6 znaków')
            return;
        }

        alert.info('Trwa tworzenie użytkownika...')
        const user = {
            firstName,
            lastName,
            login: username,
            password,
            email,
            phoneNumber,
            postalCode,
            city,
            address
        }
        userService.createUser(user, role).then(() => {
            alert.success('Utworzono nowego użytkownika')
        }).catch(err => {
            alert.error(err)
        })
    }
    return (
        <>
            <div
                className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
                style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center top",
                }}
            >
                {/* Mask */}
                <span className="mask bg-gradient-default opacity-8" />
                {/* Header container */}
                <Container className="d-flex align-items-center" fluid>
                </Container>
            </div>
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Tworzenie użytkownika</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={(e) => {e.preventDefault(); addUser();}}>
                                    <h6 className="heading-small text-muted mb-4">
                                        Rola
                                    </h6>
                                    <Row style={{ marginLeft: '3vw' }}>
                                        <Col lg="3">
                                            <FormGroup check>
                                                <Input
                                                    name="radio1"
                                                    type="radio"
                                                    checked={role === 'User'}
                                                    onClick={() => setRole('User')}
                                                />
                                                {' '}
                                                <Label check>
                                                    Użytkownik
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="3">
                                            <FormGroup check>
                                                <Input
                                                    name="radio1"
                                                    type="radio"
                                                    checked={role === 'Administrator'}
                                                    onClick={() => setRole('Administrator')}
                                                />
                                                {' '}
                                                <Label check>
                                                    Administrator
                                                </Label>
                                            </FormGroup >
                                        </Col>
                                    </Row>
                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">
                                        Dane osobowe
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        Nazwa użytkownika
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={username}
                                                        onChange={(e) => setUsername(e.target.value)}
                                                        id="input-username"
                                                        type="text"
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-first-name"
                                                    >
                                                        Imię
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={firstName}
                                                        onChange={(e) => {
                                                            setFirstName(e.target.value)
                                                            handleEmailAutoFill(e.target.value, lastName)
                                                        }}
                                                        id="input-first-name"
                                                        type="text"
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-last-name"
                                                    >
                                                        Nazwisko
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={lastName}
                                                        onChange={(e) => {
                                                            setLastName(e.target.value)
                                                            handleEmailAutoFill(firstName, e.target.value)
                                                        }}
                                                        id="input-last-name"
                                                        type="text"
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr className="my-4" />
                                    {/* Address */}
                                    <h6 className="heading-small text-muted mb-4">
                                        Dane kontaktowe
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-email"
                                                    >
                                                        Adres email
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        id="input-email"
                                                        type="email"
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-phone"
                                                    >
                                                        Numer telefonu
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={phoneNumber}
                                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                                        id="input-phone"
                                                        maxLength={15}
                                                        regex
                                                        type="phone"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-city"
                                                    >
                                                        Miejscowość
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={city}
                                                        onChange={(e) => setCity(e.target.value)}
                                                        id="input-city"
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-postal-code"
                                                    >
                                                        Kod pocztowy
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={postalCode}
                                                        onChange={(e) => setPostalCode(e.target.value)}
                                                        id="input-postal-code"
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-address"
                                                    >
                                                        Adres
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={address}
                                                        onChange={(e) => setAddress(e.target.value)}
                                                        id="input-address"
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">
                                        Hasło
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-password"
                                                    >
                                                        Hasło
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        id="input-password"
                                                        placeholder="Hasło"
                                                        type="password"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-confirm-password"
                                                    >
                                                        Powtórz hasło
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={confirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                        id="input-confirm-password"
                                                        placeholder="Powtórz hasło"
                                                        type="password"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <Button type="submit" color="success" style={{ width: '100%' }}>Dodaj użytkownika</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default CreateUser;
